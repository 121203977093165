

































































































































































import {
  BButton,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BSpinner,
  BFormCheckbox,
  BFormDatepicker,
  BInputGroup,
  BRow
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { CreateProfilEntreprise } from "@/api/models/authorization/profils/profilEntreprise";
import { required, min, max } from "@validations";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Branche } from "@/api/models/options/entreprises/branche";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { Region } from "@/api/models/options/entreprises/region";
import { Site } from "@/api/models/options/entreprises/site";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";

@Component({
  components: {
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormDatepicker,
    BInputGroup,
    SearchableVueSelect,
    BRow,
    RessifnetDateInput
  },
})
export default class AddProfilEntrepriseModal extends Vue {
  @Prop({ required: true }) loading!: boolean;

  required = required;
  min = min;
  max = max;

  newProfil: CreateProfilEntreprise = {};

  formItems: any[] = [
    {
      entrepriseId: "",
      isAllEntreprise: false,
      selectedEntreprise: null,
      isSelectedEntreprise: false,

      entreprisesOptions: [],

      selectedBranches: [],
      branchesOptions: [],
      totalCountBranches: 0,

      selectedRegions: [],
      regionsOptions: [],
      totalCountRegions: 0,

      selectedSites: [],
      sitesOptions: [],
      totalCountSites: 0,
    }
  ];

  addFormItem() {
    this.formItems.push({
      entrepriseId: "",
      isAllEntreprise: false,
      selectedEntreprise: null,
      isSelectedEntreprise: false,

      entreprisesOptions: [],

      selectedBranches: [],
      branchesOptions: [],
      totalCountBranches: 0,

      selectedRegions: [],
      regionsOptions: [],
      totalCountRegions: 0,

      selectedSites: [],
      sitesOptions: [],
      totalCountSites: 0,
    });
  }

  removeFormItem(formItem: any) {
    this.formItems.splice(this.formItems.indexOf(formItem), 1);
  }

  async onSelectedEntreprise(value: any, formItem: any) {
    formItem.selectedEntreprise = value;
    formItem.isSelectedEntreprise = true;

    formItem.selectedBranches = [];
    formItem.branchesOptions = [];
    await this.searchBranches(null, formItem)

    formItem.selectedRegions = [];
    formItem.regionsOptions = [];
    await this.searchRegions(null, formItem)

    formItem.sitesOptions = [];
    formItem.selectedSites = [];
    await this.searchSites(null, formItem)
  }

  async searchEntreprise(params: any, formItem: any) {
    if (!params || params?.reset) {
      formItem.entreprisesOptions = formItem.entreprisesOptions?.filter((e: any) => e.id == formItem.entrepriseId);
      formItem.totalCountEntreprises = formItem.entreprisesOptions.length;
    }

    await this.$http.ressifnet.entreprises
      .paginatedList(
        params?.pageNumber ?? 1, 
        params?.pageSize ?? 10, 
        params?.search ?? "")
      .then((response: PaginatedList<Entreprise>) => {
        formItem.entreprisesOptions = formItem.entreprisesOptions!.concat(response.items.filter(ri => !formItem.entreprisesOptions!.some((lsi: any) => lsi.id == ri.id)))
        // formItem.selectedEntreprise = formItem.entreprisesOptions.length === 1 ? formItem.entreprisesOptions[0] : null;
        // if(formItem.selectedEntreprise) {
        //   formItem.entrepriseId = formItem.selectedEntreprise.id;
        //   formItem.isSelectedEntreprise = true;
        // }
        formItem.totalCountEntreprises = response.totalCount;
      });
  }

  async searchBranches(params: any, formItem: any) {   
    if (!params || params?.reset) {
      formItem.branchesOptions = formItem.branchesOptions?.filter((e: any) => e.id == formItem.brancheId);
      formItem.totalCountBranche = formItem.branchesOptions.length;
    }

    await this.$http.ressifnet.branches
      .paginatedList(
        formItem.selectedEntreprise!.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Branche>) => {
        formItem.totalCountBranches = response.totalCount;
        formItem.branchesOptions = formItem.branchesOptions!.concat(
          response.items.filter(
            (ri) => !formItem.branchesOptions!.some((lsi: any) => lsi.id == ri.id)
          )
        )
      });
  }


  async searchRegions(params: any, formItem: any) {
    if (!params || params?.reset) {
      formItem.regionsOptions = formItem.regionsOptions?.filter((e: any) => e.id == formItem.regionId);
      formItem.totalCountRegion = formItem.regionsOptions.length;
    }

    await this.$http.ressifnet.regions
      .paginatedList(
        formItem.selectedEntreprise!.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Region>) => {
        formItem.totalCountRegions = response.totalCount;        
        formItem.regionsOptions = formItem.regionsOptions!.concat(
          response.items.filter(
            (ri) => !formItem.regionsOptions!.some((lsi: any) => lsi.id == ri.id)
          )
        )
      });
  }

  async searchSites(params: any, formItem: any) {
    if (!params || params?.reset) {
      formItem.sitesOptions = formItem.sitesOptions?.filter((e: any) => e.id == formItem.siteId);
      formItem.totalCountSite = formItem.sitesOptions.length;
    }

    await this.$http.ressifnet.sites
      .paginatedList(
        formItem.selectedEntreprise!.id,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((response: PaginatedList<Site>) => {    
        formItem.totalCountSites = response.totalCount;        
        formItem.sitesOptions = formItem.sitesOptions!.concat(
          response.items.filter(
            (ri) => !formItem.sitesOptions!.some((lsi: any) => lsi.id == ri.id)
          )
        )
      });
  }

  async createProfilEntreprises(event: any) {
    event.preventDefault();
    (this.$refs.formValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then((isSuccess: any) => {
        if (isSuccess) {
          this.$emit("add-profil-entreprises", this.formItems);
        }
      });
  }
}
