










































import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BSpinner, BFormSelect
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Fonction } from '@/api/models/authorization/fonctions/fonction';
import { CreateProfil } from '@/api/models/authorization/profils/profil';
import { required } from '@validations'
import { PaginatedList } from '@/api/models/common/paginatedList';
import RessifnetDateInput from '@/components/inputs/RessifnetDateInput.vue';

@Component({
  components: {
    BButton, 
    BModal, 
    BForm, 
    BFormInput,
    BFormGroup,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    RessifnetDateInput,
    BFormSelect
  },
})

export default class AddProfilModal extends Vue {
  @Prop({ required: true }) loading!: boolean

  fonctionOptions!: any[]

  required = required;

  newProfil: CreateProfil = {}

  created() {
    this.loadFonctions()
  }

  async loadFonctions() {
    await this.$http.ressifnet.fonctions
      .paginatedList(1, 30)
      .then((response: PaginatedList<Fonction>) => {
        this.fonctionOptions = response.items;
        this.newProfil.fonctionId = this.fonctionOptions.length === 1 ? this.fonctionOptions[0].id : undefined;
      });
  }

  async createProfil(event: any) {
    event.preventDefault();
    (this.$refs.formValidation as InstanceType<typeof ValidationObserver>)
      .validate()
      .then((isSuccess: any) => {
        if (isSuccess) {
          this.$emit('add-profil', this.newProfil);
        }
      })
  }
}
