













































































import { BSpinner } from 'bootstrap-vue'
import { Component, Vue, Prop } from "vue-property-decorator";
import { UserDetail } from "@/api/models/authorization/userDetails/userDetail";
import { EditProfil, Profil } from "@/api/models/authorization/profils/profil";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";
import AddProfilModal from "./AddProfilModal.vue";
import RessifnetDateInput from "@/components/inputs/RessifnetDateInput.vue";
import DisplayIcon from '@/components/icons/DisplayIcon.vue'
import DeleteIcon from '@/components/icons/DeleteIcon.vue'
import EditIcon from '@/components/icons/EditIcon.vue'

@Component({
  components: {
    AddProfilModal,
    BSpinner,
    RessifnetDateInput,
    DisplayIcon,
    DeleteIcon,
    EditIcon
  },
})
export default class ProfilsList extends Vue {
  @Prop() listProfils!: PaginatedList<Profil>;
  @Prop() selectedUserDetail!: UserDetail;
  @Prop({ required: false, default: null}) selectedProfil!: Profil | null;

  mainLoading: boolean = false;
  modalLoading: boolean = false;

  profilToEdit: EditProfil = {
    deletionDate: "",
    fonctionId: "",
    identityId: "",
    profilId:""
  };

  async editDeletionDate(profil : Profil){
    await this.$http.ressifnet.profils
    .get(profil.identityId, profil.id)
    .then(
        (response: Profil) => {
          this.profilToEdit.profilId = response.id
          this.profilToEdit.identityId = response.identityId
          this.profilToEdit.fonctionId = response.fonction.id
          this.profilToEdit.deletionDate = response.deletionDate
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement du profil",
          });
        }
      );
  }

  async confirmEdit(profilToEdit: EditProfil){
    await this.$http.ressifnet.profils
    .put(profilToEdit.identityId, profilToEdit.profilId, profilToEdit)
    .then(
        (response: any) => {
          successAlert.fire({
          title: "modification de la date d'expiration",
          text: "Modification effectué avec succès",
        });
        this.$emit('reload-profils');
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors de la modification",
          });
        }
      );
  }

  async askDeleteProfilIdentity(profilToDelete: Profil) {
    this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer le profil ?', {
          title: 'Confirmation de suppression',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value === true){
            await this.$http.ressifnet.profils
              .deleteProfilIdentity(profilToDelete.identityId, profilToDelete.id)
              .then(
                async (response: any) => {
                  this.$emit("reload-profils");
                  successAlert.fire({
                    title: "Suppression d'un profil à un utilisateur",
                    text: "Suppression effectuée avec succès",
                  });
                },
                (error: any) => {
                  if (
                    error?.response?.data?.errors &&
                    Object.keys(error.response.data.errors).length
                  ) {
                    errorAlert.fire({
                      text: error.response.data.errors[
                        Object.keys(error.response.data.errors)[0]
                      ],
                    });
                  } else {
                    errorAlert.fire({
                      text: error?.response?.data?.detail ?? error.message,
                    });
                  }
                }
              );
          } 
          else {
            return
          }
        })
    
  }

  async createProfil(newProfil: Profil) {
    this.modalLoading = true;
    newProfil.identityId = this.selectedUserDetail.identity.id;
    await this.$http.ressifnet.profils.post(newProfil).then(
      async (response: string) => {
        this.$emit("reload-profils");
        this.$bvModal.hide("modal-add-profil");
        successAlert.fire({
          title: "Création d'un profil",
          text: "Ajout effectué avec succès",
        });
      },
      (error: any) => {
        this.$bvModal.hide("modal-add-profil");
        if (
          error?.response?.data?.errors &&
          Object.keys(error.response.data.errors).length
        ) {
          errorAlert.fire({
            text: error.response.data.errors[
              Object.keys(error.response.data.errors)[0]
            ],
          });
        } else {
          errorAlert.fire({
            text: error?.response?.data?.detail ?? error.message,
          });
        }
      }
    );
    this.modalLoading = false;
  }

  async selectProfil(profil: Profil) {
    this.mainLoading = true
    await this.$http.ressifnet.profils
      .get(profil.identityId, profil.id, 1, 50)
      .then(
        (response: Profil) => {
          this.$emit('select-profil', response)
          this.mainLoading = false;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des détails utilisateur",
          });
        }
      );
  }
}
