












































import { Component, Vue, Prop } from "vue-property-decorator";
import { Profil } from "@/api/models/authorization/profils/profil";
import { Droit } from "@/api/models/authorization/droits/droit";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {},
})
export default class DroitProfils extends Vue {
  @Prop() selectedProfil!: Profil
  listDroits: PaginatedList<Droit> = new PaginatedList<Droit>()
  mainLoading: boolean = false
  modalLoading: boolean = false

  created() {
    this.loadDroits()
  }

  async loadDroits() {
    this.mainLoading = true;

    await this.$http.ressifnet.droits
      .paginatedList(1, 20)
      .then((response: PaginatedList<Droit>) => {
        this.listDroits = response;
        this.mainLoading = false;
      });
  }

  droitChecked(droit: Droit) {
    return this.selectedProfil.droits?.some((x: Droit) => x.id == droit.id) || this.isDroitFonction(droit)
	}

  isDroitFonction(droit: Droit) {
    return this.selectedProfil.fonction.droits.some((x: Droit) => x.id == droit.id)
  }

  checkboxChange(droit: Droit, event: any) {
		if (event.target.checked) {
       this.askAddDroit(droit)
    } else {
			this.askRemoveDroit(droit)
		}
	}

  async askAddDroit(droit: Droit) {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir ajouter ce droit à cet utilisateur?",
        {
          title: "Confirmation d'ajout",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.addDroitProfil(droit);
        } else {
          return;
        }
      });
  }

  async askRemoveDroit(droit: Droit) {
    this.$bvModal
      .msgBoxConfirm(
        "Etes-vous sûr de vouloir supprimer ce droit à cet utilisateur?",
        {
          title: "Confirmation de suppression",
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      )
      .then(async (value) => {
        if (value === true) {
          await this.removeDroitProfil(droit);
        } else {
          return;
        }
      });
  }


  async addDroitProfil(droit: Droit) {
		if (!this.selectedProfil) return;

		await this.$http.ressifnet.profils.postDroitProfil(this.selectedProfil.id, droit.id, this.selectedProfil.identityId)
			.then(async (response: any) => {
				this.$emit('reload-profils');
				successAlert.fire({
					title: 'Ajout d\'un droit à l\'utilisateur',
					text: 'Ajout effectué avec succès'
				})
			}, (error: any) => {
				if (error.response?.data?.detail && error.response.data.detail.length > 0){
          errorAlert.fire({
            text: error.response.data.detail
          })
        } else {
          errorAlert.fire({
            text: error.message
          })
        }
        this.$emit('reload-profils');
			})
	}

	async removeDroitProfil(droit: Droit) {
		if (!this.selectedProfil) return;

		await this.$http.ressifnet.profils.deleteDroitProfil(this.selectedProfil.id, droit.id, this.selectedProfil.identityId)
			.then(async (response: any) => {
				this.$emit('reload-profils');
				successAlert.fire({
					title: 'Suppression d\'un droit à un profil',
					text: 'Suppression effectuée avec succès'
				})
			}, (error: any) => {
				if (error.response?.data?.detail && error.response.data.detail.length > 0){
          errorAlert.fire({
            text: error.response.data.detail
          })
        } else {
          errorAlert.fire({
            text: error.message
          })
        }
			})	  
	}
}
