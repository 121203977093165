














































































import { Component, Vue, Prop } from "vue-property-decorator";
import { Profil } from "@/api/models/authorization/profils/profil";
import { ProfilEntreprise } from "@/api/models/authorization/profils/profilEntreprise";
import { PaginatedList } from "@/api/models/common/paginatedList";
import AddProfilEntrepriseModal from "./AddProfilEntrepriseModal.vue";
import { Region } from "@/api/models/options/entreprises/region";
import { Site } from "@/api/models/options/entreprises/site";
import { Branche } from "@/api/models/options/entreprises/branche";
import { successAlert, errorAlert } from "@/libs/sweetAlerts/alerts";

@Component({
  components: {
    AddProfilEntrepriseModal,
  },
})
export default class ProfilEntreprises extends Vue {
  @Prop() selectedProfil!: Profil;
  listProfilEntreprises!: PaginatedList<ProfilEntreprise>;
  modalLoading: boolean = false;

  created() {
    this.listProfilEntreprises = this.selectedProfil.profilEntreprises;
  }

  async addProfilEntreprises(formItems: any[]) {
    this.modalLoading = true;
    var profilEntreprises = this.formatProfilEntreprises(formItems);

    new Promise(async(resolve) => {
      for(var profilEntreprise of profilEntreprises) {
        console.log(profilEntreprise)
        await this.$http.ressifnet.profils
          .postProfilEntreprise(
            this.selectedProfil.identityId,
            this.selectedProfil.id,
            profilEntreprise
        )
      }
      resolve(true)
    }).then(
      async () => {
        this.$bvModal.hide("modal-add-profil-entreprise");
        this.$emit('reload-profils');
        successAlert.fire({
          title: "Ajout d'un droit d'accès à un profil",
          text: "Ajout effectué avec succès",
        });

        this.modalLoading = false;
      },
      (error: any) => {
        this.$bvModal.hide("modal-add-profil-entreprise");
        errorAlert.fire({
          title: "Oops",
          text: error.message,
        });
      }
    );
  }

  formatProfilEntreprises(formItems: any[]) {
    var pes: any[] = [];
    formItems.forEach((item) => {
      if (
        item.isAllEntreprise || 
        (
          (!item.selectedBranches || !item.selectedBranches?.length)&&
          (!item.selectedRegions || !item.selectedRegions?.length)&&
          (!item.selectedSites || !item.selectedSites?.length)
        )) {
        var pe = {
          profilId: this.selectedProfil.id,
          identityId: this.selectedProfil.identityId,
          entrepriseId: item.selectedEntreprise.id,
        };
        pes.push(pe);
      } else {
        // add branches
        item.selectedBranches?.forEach((brancheId: string) => {
          var pe = {
            profilId: this.selectedProfil.id,
            identityId: this.selectedProfil.identityId,
            entrepriseId: item.selectedEntreprise.id,
            brancheId: brancheId,
          };
          pes.push(pe);
        });

        // add regions
        item.selectedRegions?.forEach((region: Region) => {
          var pe = {
            profilId: this.selectedProfil.id,
            identityId: this.selectedProfil.identityId,
            entrepriseId: item.selectedEntreprise.id,
            regionId: region.id,
          };
          pes.push(pe);
        });

        // add sites
        item.selectedSites?.forEach((site: Site) => {
          var pe = {
            profilId: this.selectedProfil.id,
            identityId: this.selectedProfil.identityId,
            entrepriseId: item.selectedEntreprise.id,
            siteId: site.id,
            regionId: site.regionId,
          };
          pes.push(pe);
        });
      }
    });

    return pes;
  }

  async askDeleteProfilEntreprise(profilEntreprise: any){
    this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer le profil entreprise ?', {
          title: 'Confirmation de suppression',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if(value === true){
            await this.deleteProfilEntreprise(profilEntreprise)
          } 
          else {
            return
          }
        })
  }

  async deleteProfilEntreprise(profilEntreprise: any) {
		if (!this.selectedProfil) return;
    new Promise(async(resolve) => {
      await this.$http.ressifnet.profils.deleteProfilEntreprise(this.selectedProfil.id, profilEntreprise.id, this.selectedProfil.identityId)
      resolve(true)
    }).then(async (response: any) => {
      this.$emit('reload-profils');
      successAlert.fire({
        title: 'Suppression d\'un droit d\'accès à un profil',
        text: 'Suppression effectuée avec succès'
      })
    }, (error: any) => {
      errorAlert.fire({
        title: 'Oops',
        text: error.message
      })
    })	 
	}
}
