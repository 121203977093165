






































































import { BSpinner } from "bootstrap-vue";
import { Component, Vue, Prop } from "vue-property-decorator";
import { UserDetail } from "@/api/models/authorization/userDetails/userDetail";
import { Profil } from "@/api/models/authorization/profils/profil";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import ProfilsList from "./ProfilsList.vue";
import DroitProfils from "../droits/DroitProfils.vue";
import TransfertDossiers from "./TransfertDossiers.vue";
import ProfilEntreprises from "./ProfilEntreprises.vue";
import { Identity } from "@/api/models/authorization/identities/identity";

@Component({
  components: {
    ProfilsList,
    DroitProfils,
    ProfilEntreprises,
    BSpinner,
    TransfertDossiers
  },
})
export default class Index extends Vue {
  selectedUserDetail!: UserDetail;

  listProfils: PaginatedList<Profil> = new PaginatedList<Profil>();
  selectKey!: UserDetail;
  selectedProfil: Profil | null = null;

  mainLoading: boolean = false;
  modalLoading: boolean = false;

  created() {
    this.loadUserDetails();
  }

  async loadUserDetails() {
    this.mainLoading = true;
    await this.$http.ressifnet.userDetails
      .get(this.$route.params.userDetailsId)
      .then(
        (response: UserDetail) => {
          this.selectedUserDetail = response;
          this.getIdentity(this.selectedUserDetail);
          this.loadProfils();
          this.mainLoading = false;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des détails de l'utilisateur",
          });
        }
      );
  }


  async getIdentity(userDetail: UserDetail) {
    await this.$http.ressifnet.identities.get(userDetail.id).then(
      (response: Identity) => {
        this.selectedUserDetail!.identity = response;
        this.mainLoading = false;
      },
      (error: any) => {
        errorAlert.fire({
          title: "Erreur lors du chargement des détails de l'utilisateur",
        });
      }
    );
  }

  reloadProfils() {
    this.loadProfils();
  }

  async loadProfils() {
    this.mainLoading = true;
    const selectedProfilId = this.selectedProfil?.id;
    await this.$http.ressifnet.profils
      .paginatedList(this.selectedUserDetail.id, 1, 50)
      .then(
        (response: PaginatedList<Profil>) => {
          this.selectedProfil = null;
          this.listProfils = response;
          if (selectedProfilId) {
            this.selectedProfil = this.listProfils.items.filter(
              (x) => x.id == selectedProfilId
            )[0];
          }
          this.mainLoading = false;
        },
        (error: any) => {
          errorAlert.fire({
            title: "Erreur lors du chargement des détails utilisateur",
          });
        }
      );
  }

  selectProfil(profil: Profil) {
    this.selectedProfil = profil;
  }
}
