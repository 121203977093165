



























































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import SearchableVueSelect from "@/components/selects/SearchableVueSelect.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import { PaginatedList } from "@/api/models/common/paginatedList";
import { Entreprise } from "@/api/models/options/entreprises/entreprise";
import { Site } from "@/api/models/options/entreprises/site";
import { Region } from "@/api/models/options/entreprises/region";
import { Branche } from "@/api/models/options/entreprises/branche";
import { Secteur } from "@/api/models/options/entreprises/secteur";
import {
  DossiersReferentCount,
  TransfertDossierProfilSearchParams,
} from "@/api/models/dossiers/dossier";
import { UserDetail } from "@/api/models/authorization/userDetails/userDetail";
import { errorAlert, successAlert } from "@/libs/sweetAlerts/alerts";
import { BSpinner } from "bootstrap-vue";

@Component({
  components: {
    SearchableVueSelect,
    ValidationObserver,
    ValidationProvider,
    BSpinner,
  },
})
export default class TransfertDossiers extends Vue {
  required = required;

  countLoading = true;
  updateLoading = false;

  model: TransfertDossierProfilSearchParams = {
    isGlobal: true,
    // referentId: this.$route.params.userDetailsId,
  };

  listeEntreprises: Entreprise[] = [];
  totalCountEntreprise = 0;

  listeSites: Site[] = [];
  totalCountSite = 0;

  listeSecteurs: Secteur[] = [];
  totalCountSecteur = 0;

  listeBranches: Branche[] = [];
  totalCountBranche = 0;

  listeRegions: Region[] = [];
  totalCountRegion = 0;

  selectedCategorie: any = null;

  brancheLibelle = "";
  regionLibelle = "";

  searchReferentsTotalCount = 0;
  searchReferentsItems: UserDetail[] = [];

  countDossiersReferent: DossiersReferentCount = {
    dossierCount: 0,
    isGlobal: this.model.isGlobal!,
  };

  timeout: any;

  async created() {
    await this.getDossiersReferentCount();
  }

  async intervalReferentCount() {
    this.countLoading = true;
    clearTimeout(this.timeout);
    this.timeout = setTimeout(async () => {
      await this.getDossiersReferentCount();
    }, 3000);
  }

  // async searchReferent(params: any) {
  //   this.countLoading = true;
  //   if (!params || params?.reset) {
  //     this.searchReferentsTotalCount = 0;
  //     this.searchReferentsItems = [];
  //   }
    
  //   await this.$http.ressifnet.dossiers
  //     .paginatedListReferents(
  //       params?.pageNumber ?? 1,
  //       params?.pageSize ?? 10,
  //       params?.search ?? ""
  //     )
  //     .then(
  //       (response: PaginatedList<UserDetail>) => {
  //         this.searchReferentsTotalCount = response.totalCount;
  //         this.searchReferentsItems = this.searchReferentsItems!.concat(response.items.filter(ri => !this.searchReferentsItems!.some(lsi => lsi.id == ri.id)))
  //         // this.model.newReferentId = this.searchReferentsItems.length === 1 ? this.searchReferentsItems[0].id : undefined;
  //         // this.model.referentId = this.searchReferentsItems.length === 1 ? this.searchReferentsItems[0].id : undefined;
  //         this.countLoading = false;
  //       },
  //       (error: any) => {
  //         if (
  //           error?.response?.data?.errors &&
  //           Object.keys(error.response.data.errors).length
  //         ) {
  //           errorAlert.fire({
  //             text: error.response.data.errors[
  //               Object.keys(error.response.data.errors)[0]
  //             ],
  //           });
  //         } else {
  //           errorAlert.fire({
  //             text: error?.response?.data?.detail ?? error.message,
  //           });
  //         }
  //       }
  //     );
  // }

  async getDossiersReferentCount() {
    this.countLoading = true;
    await this.$http.ressifnet.dossiers
      .getDossiersReferentCount(this.$route.params.userDetailsId, this.model)
      .then(
        (response: DossiersReferentCount) => {
          this.countDossiersReferent = response;
          this.countLoading = false;
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async confirmUpdateReferent() {
    await this.getDossiersReferentCount();
    this.$swal({
      title: `Transfert de dossiers`,
      text: `Êtes-vous sûr de vouloir transférer ${
        this.countDossiersReferent
          ? this.countDossiersReferent.dossierCount
          : "0"
      } dossier(s) ?`,
      icon: "warning",
      showCancelButton: true,
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (val) => {
      if (val.isConfirmed) {
        await this.updateReferent();
      }
    });
  }

  async updateReferent() {
    this.updateLoading = true;
    await this.$http.ressifnet.dossiers
      .putReferent(this.$route.params.userDetailsId, this.model)
      .then(
        async (response: any) => {
          successAlert.fire({
            title: "Transfert de dossiers",
            text: "Transfert effectué avec succès",
          });

          this.model = {
            isGlobal: true,
          };
          this.updateLoading = false;
          await this.getDossiersReferentCount();
        },
        (error: any) => {
          if (
            error?.response?.data?.errors &&
            Object.keys(error.response.data.errors).length
          ) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error?.response?.data?.detail ?? error.message,
            });
          }
        }
      );
  }

  async searchEntreprises(params: any) {
    if (!params || params?.reset) {
      this.listeEntreprises = this.listeEntreprises?.filter(e => e.id == this.model.entrepriseId);
      this.totalCountEntreprise = this.listeEntreprises.length;
    }

    this.onClearSite();
    await this.$http.ressifnet.entreprises
      .paginatedList(
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((res: PaginatedList<Entreprise>) => {
        this.totalCountEntreprise = res.totalCount;
        this.listeEntreprises = this.listeEntreprises!.concat(
          res.items.filter(
            (ri) => !this.listeEntreprises!.some((lsi) => lsi.id == ri.id)
          )
        );
        // if(!this.model.isGlobal) this.model.entrepriseId = this.listeEntreprises.length === 1 ? this.listeEntreprises[0].id : undefined;
      });
  }

  async searchSites(params: any) {
    if (!params || params?.reset) {
      this.listeSites = this.listeSites?.filter(e => e.id == this.model.siteId);
      this.totalCountSite = this.listeSites.length;
    }

    await this.$http.ressifnet.sites
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? "",
        this.model.regionId,
        this.model.brancheId
      )
      .then((res: PaginatedList<Site>) => {
        this.totalCountSite = res.totalCount;
        this.listeSites = this.listeSites!.concat(
          res.items.filter(
            (ri) => !this.listeSites!.some((lsi) => lsi.id == ri.id)
          )
        );
      });
  }

  async searchSecteurs(params: any) {
    if (!params || params?.reset) {
      this.listeSecteurs = this.listeSecteurs?.filter(e => e.id == this.model.secteurId);
      this.totalCountSecteur = this.listeSecteurs.length;
    }

    await this.$http.ressifnet.secteurs
      .paginatedListForSite(
        this.model.entrepriseId,
        this.model.siteId ?? "",
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search ?? ""
      )
      .then((res: PaginatedList<Secteur>) => {
        this.totalCountSite = res.totalCount;
        this.listeSecteurs = this.listeSecteurs!.concat(
          res.items.filter(
            (ri) => !this.listeSecteurs!.some((lsi) => lsi.id == ri.id)
          )
        );
      });
  }

  async searchBranches(params: any) {
    if (!params || params?.reset) {
      this.listeBranches = this.listeBranches?.filter(e => e.id == this.model.brancheId);
      this.totalCountBranche = this.listeBranches.length;
    }

    await this.$http.ressifnet.branches
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search
      )
      .then((res: PaginatedList<Branche>) => {
        this.totalCountBranche = res.totalCount;
        this.listeBranches = this.listeBranches!.concat(
          res.items.filter(
            (ri) => !this.listeBranches!.some((lsi) => lsi.id == ri.id)
          )
        );
      });
  }

  async searchRegions(params: any) {
    if (!params || params?.reset) {
      this.listeRegions = this.listeRegions?.filter(e => e.id == this.model.regionId);
      this.totalCountRegion = this.listeRegions.length;
    }

    await this.$http.ressifnet.regions
      .paginatedList(
        this.model.entrepriseId,
        params?.pageNumber ?? 1,
        params?.pageSize ?? 10,
        params?.search
      )
      .then((res: PaginatedList<Region>) => {
        this.totalCountRegion = res.totalCount;
        this.listeRegions = this.listeRegions!.concat(
          res.items.filter(
            (ri) => !this.listeRegions!.some((lsi) => lsi.id == ri.id)
          )
        );
      });
  }

  onEntrepriseSelected(val: any) {
    if (val) {
      const entreprise = this.listeEntreprises?.filter((s) => s.id == val)[0];
      this.model.siteId = undefined;
      this.model.regionId = undefined;
      this.model.brancheId = undefined;
      this.model.secteurId = undefined;
      this.listeSites = [];
      this.brancheLibelle =
        entreprise?.site?.brancheLibelle ?? "Ce site n'a pas de branche";
      this.regionLibelle =
        entreprise?.site?.regionLibelle ?? "Ce site n'a pas de région";
      this.searchRegions(null);
      this.searchBranches(null);
      this.searchSites(null);
      this.searchSecteurs(null)
      this.intervalReferentCount();
    }
  }

  onSiteSelected(val: any) {
    if (val) {
      const site = this.listeSites?.filter((s) => s.id == val)[0];
      this.model.brancheId = undefined;
      this.brancheLibelle =
        site?.brancheLibelle ?? "Ce site n'a pas de branche";
      this.regionLibelle = site?.regionLibelle ?? "Ce site n'a pas de région";
    }
  }

  @Watch("model.isGlobal")
  onClearEntreprise() {
    this.model.entrepriseId = undefined;
    this.searchEntreprises(null);
  }

  @Watch("model.isGlobal")
  onClearSite() {
    this.model.siteId = undefined;
    this.searchSites(null);
  }

  @Watch("model.isGlobal")
  onClearRegion() {
    this.searchRegions(null);
    this.searchSites(null);
  }

  @Watch("model.isGlobal")
  onClearBranche() {
    this.searchBranches(null);
    this.searchSites(null);
  }
}
